<p-toast key="pdfFormToast" position="top-center" [baseZIndex]="10000"></p-toast>
<p-confirmDialog key="pdfFormConfirmDialog" header="PDF Action Confirmation" width="425" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<div class="flex-column" style="height: calc(100% - 0px) !important; overflow-y: hidden !important;">
  <div class="border-2 border-round shadow-4 mb-2 border-gray-500 " style="height: calc(100% - 60px) !important; overflow-y: hidden !important;">

    <ngx-extended-pdf-viewer #pdfViewer *ngIf="url" [src]="url" [height]="'100%'" [zoom]="'page-width'"
      [useBrowserLocale]="true" [textLayer]="true">
    </ngx-extended-pdf-viewer>
  
  </div>
  <div style="height: 50px; min-width: 100%; padding-top: 10px;">
    <p-divider layout="horizontal" styleClass="flex md:hidden" [align]="'center'"></p-divider>
    <div class="flex justify-content-end gap-1 ">
      <!-- <div style="min-height: 100%;"> -->
        <!-- <div> -->
          <p-button label="Save" icon="pi pi-file" (onClick)="savePdfData()"/>
        <!-- </div> -->
        <!-- <div> -->
          <p-button label="Close" icon="pi pi-times-circle" severity="danger" (onClick)="closePdfForm()"/>
        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>
