import { Component, OnInit, OnDestroy, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Loading } from 'notiflix';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { DividerModule } from 'primeng/divider';

@Component({
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
      NgxExtendedPdfViewerModule,
      CardModule,
      CommonModule,
      NgIf,
      ButtonModule,
      ConfirmDialogModule,
      ToastModule,
      DividerModule,
    ],
    providers: [MessageService, ConfirmationService],
    selector: 'pdf-form',
    templateUrl: './pdf-form.component.html',
    styleUrls: ['./pdf-form.component.scss'],
})
export class PdfFormComponent implements OnInit {

    url = '';

    constructor(
        private config: DynamicDialogConfig,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private ref: DynamicDialogRef
    ) {
        pdfDefaultOptions.assetsFolder = 'assets';
        this.url = this.config.data.url;
    }

    ngOnInit() {
        console.log('PDF Form Component initialized');    
    }

    ngOnDestroy() {
        console.log('PDF Form Component destroyed');
    }

    savePdfData() {
        try {
        this.confirmationService.confirm({
            key: 'pdfFormConfirmDialog',
            header: 'Save Changes',
            message: `Are you sure you want to save your PDF form changes?`,
            icon: 'pi pi-exclamation-question',
            reject: () => {
              // Do nothing on reject
            },
            accept: async () => {
                try {
                    Loading.standard('Saving PDF form changes...');
                    await new Promise(resolve => setTimeout(resolve, 1000 * 2));
                    throw 'Unable to save change. Data field mismatch encountered.';
                } catch (error: any) {
                    this.messageService.add({
                        key: 'pdfFormToast',
                        severity: 'error', summary: 'Unable to save PDF form changes...',
                        detail: `The form was not saved. Please try again or contact IT support for help. ${error.message}`
                      });
                } finally {
                    Loading.remove();
                }
            }        
          });
        } catch (error: any) {
            console.error(`Error saving PDF form data: ${error.message}`);
        }
    }

    closePdfForm() {
        this.confirmationService.confirm({
            key: 'pdfFormConfirmDialog',
            header: 'Close PDF Form',
            message: `Are you sure you want to close the PDF form?`,
            icon: 'pi pi-exclamation-question',
            reject: () => {
              // close
            },
            accept: async () => {
                this.ref.close();
            }        
          });
    }
}